var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'tip': true,
    'tip-show': _vm.isShow,
    ['tip-arrow-' + _vm.arrowpos]: true,
    },style:({
    'background-color': _vm.backgound,
    'padding': _vm.padding + 'px',
    'box-shadow': _vm.shadow ||'none',
    'border-radius': _vm.borderradius + 'px',
    'transform': 'translate(calc(-50% ' + (_vm.offset[0] < 0 ? '- ' : '+ ') + Math.abs(_vm.offset[0]) + 'px), calc(-100% - ' + (_vm.isShow ? 15 : 0) + 'px ' + (_vm.offset[1] < 0 ? '- ' : '+ ') + Math.abs(_vm.offset[1]) + 'px))'
    }),on:{"mouseenter":function($event){$event.stopPropagation();return _vm.stopPropagation()},"mouseleave":function($event){$event.stopPropagation();return _vm.stopPropagation()}}},[(_vm.url)?_c('img',{staticClass:"tip-img",style:({width: _vm.size[0] + 'px', height: _vm.size[1] + 'px'}),attrs:{"src":_vm.url,"alt":""}}):_vm._e(),(_vm.text)?_c('p',{staticClass:"tip-text",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }