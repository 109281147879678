<template>
    <div :class="{'side': true, 'side-mobile-show': $store.state.isSideShow}">

        <p class="side-close">
            <i class="iconfont icon-close" @click="$store.commit('mobileSideShow', false)"></i>
        </p>
        <div class="box">
            <!-- 用户信息 -->
            <div class="avatar" @mouseenter="$refs.tipbox.isShow = true" @mouseleave="$refs.tipbox.isShow = false">
                <tipBox :ref="'tipbox'" :text="$store.state.setting.quitelyMo ? '当前为学习模式' : ($store.state.user.level ? levelDesc : '登录即可开启等级')" :padding="10" :borderradius="2" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
                {{ $store.state.setting.quitelyMo ? '读者' : ($store.state.user.level || '无名') }}
            </div>
            <p class="name">{{$store.state.user.name || ''}}</p>
            <p class="email"></p>
            <p class="text" v-if="$store.state.user.id">今天是你{{$store.state.setting.quitelyMo ? '关注世界' : '摸鱼'}}的第 {{$store.state.user.days}} 天</p>
            <p class="text" v-else>登录后可使用自定义订阅功能</p>
            <p class="text">实时{{$store.state.setting.quitelyMo ? '学习' : '摸鱼'}}人数：{{onlineCount}}</p>
            <div class="button-box-pc" v-if="$store.state.user.id">
                <button class="btn btn-red-full btn-block subscribe" @click="$router.push({path: '/subscribe'})">管理订阅</button>
            </div>

            <!-- 移动端一直显示管理订阅 -->
            <div class="button-box-mobile">
                <button class="btn btn-red-full btn-block subscribe" @click="$router.push({path: '/subscribe'});$store.commit('mobileSideShow', false)">管理订阅</button>
            </div>

            <!-- 设置和其他功能 -->
            <ul class="setting-bar">
                <li class="setting-li" @click="$store.commit('settingShow', true)" title="设置">
                    <i class="iconfont icon-setting"></i>
                </li>
                <a href="https://peal.cc/player" title="音乐" target="_blank">
                    <li>
                        <i class="iconfont icon-musicfill"></i>
                    </li>
                </a>
                <li class="hover-show" @mouseenter="$refs.tipbox2.isShow = true" @mouseleave="$refs.tipbox2.isShow = false" style="position:relative">
                    <i class="iconfont icon-jinqun" @click="openSetting"></i>
                    <tipBox :ref="'tipbox2'" :text="'<br />群号：607942298<br />验证：摸鱼'" :url="require('../../assets/qq.png')" :padding="10" :borderradius="2" :size="[200, 200]" :offset="[0, 0]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
                </li>
                <a href="https://support.qq.com/products/313868?" title="留言反馈" target="_blank">
                    <li>
                        <i class="iconfont icon-fankui"></i>
                    </li>
                </a>
            </ul>
        </div>

        <!-- 今日热门 -->
        <div class="box hot-top-box" v-if="$store.state.setting.daytop">
            <div class="hot-title">
                <span><i class="hot-title-emoji">🔥</i> 今日热门</span>
                <span :class="{'top-refresh': topLoading}" @click="!topLoading && topRefresh()">
                    <i class="iconfont icon-refresh"></i>
                </span>
            </div>
            <ul class="hot-content">
                <li v-for="(item, i) in topList" :key="i">
                    <a :href="item.link" target="_blank" :title="item.title" @click="record(item.id)">
                        <span><span>{{i + 1}}. </span>{{item.title}}</span>
                        <span>{{item.name}}</span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- 今日赚钱提醒 -->
        <div class="tips moyu-schedule-tips" v-if="$store.state.setting.workTime.isOpen && $store.state.user.id">
            <div class="moyu-schedule">
                <p>💰️ {{moyuScheduleText}}</p>
                <div class="moyu-schedule-bar">
                    <div :class="{'moyu-schedule-bar-inside': true, 'moyu-schedule-bar-inside-done': moyuScheduleWidth === 100}" :style="'width:' + moyuScheduleWidth + '%'"></div>
                </div>
            </div>
        </div>

        <!-- 摸鱼提醒和倒计时 -->
        <div class="tips">
            <!-- 在{{$store.state.setting.quitelyMo ? '同学' : '鱼友'}}建议下已增加许多有趣的功能，如果你有更好的idea或者收录需求可以
            <a href="https://support.qq.com/products/313868?" target="_blank">在这里留言</a>。
            <br />
            目前微信群已到达扫码进群上限，想进群{{$store.state.setting.quitelyMo ? '学习' : '摸鱼'}}可以先
            <a href="javascript:void(0);" @mouseenter="$refs.tipbox.isShow = true" @mouseleave="$refs.tipbox.isShow = false" style="position:relative">
                <span>添加微信</span>
                <tipBox :ref="'tipbox'" :url="require('../../assets/qrcode.jpg')" :padding="10" :borderradius="2" :size="[200, 200]" :offset="[0, -10]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
            </a>
            拉你进群。
            <br /> -->
            <p><span>📣 </span><span v-html="boardText"></span></p>
            <br />
            <div class="tips-button">
                <p>
                    <a href="https://peal.cc/blog/34" target="_blank">《致各位{{$store.state.setting.quitelyMo ? '同学' : '鱼友'}}的一封信》</a>
                </p>
                <p>
                    <a href="https://peal.cc/blog/38" target="_blank">《赞助名单公示》</a>
                    <!-- <a href="https://peal.cc/notice" target="_blank">公告栏</a> -->
                </p>
            </div>
        </div>

        <!-- <div class="tips"> -->
        <!-- <p>
                <a href="javascript:void(0);" @mouseenter="$refs.tipbox1.isShow = true" @mouseleave="$refs.tipbox1.isShow = false" style="position:relative">
                    <span>🧧 支付宝大红包活动又来了，每日可领取~</span>
                    <tipBox :ref="'tipbox1'" :url="require('../../assets/zfbhb.jpg')" :padding="0" :borderradius="0" :size="[300, 435]" :offset="[0, -10]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
                </a>
            </p> -->
        <!-- <p>
                <span><a href="https://peal.cc/notice" target="_blank">摸摸鱼公告栏</a> 已上线，之后一切活动包括公告都可以在这里进行查看。</span>
            </p> -->
        <!-- <p>
                欢迎<a href="https://support.qq.com/products/313868?" target="_blank"> 反馈问题 </a>；
                <a href="javascript:void(0);" @mouseenter="$refs.tipbox1.isShow = true" @mouseleave="$refs.tipbox1.isShow = false" style="position:relative">
                    <span>添加微信</span>
                    <tipBox :ref="'tipbox1'" :url="require('../../assets/qrcode.jpg')" :padding="10" :borderradius="2" :size="[200, 200]" :offset="[0, -10]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
                </a>
                欢迎加入
                <span class="hover-show" @mouseenter="$refs.tipbox2.isShow = true" @mouseleave="$refs.tipbox2.isShow = false" style="position:relative">
                    <span>QQ群</span>
                    <tipBox :ref="'tipbox2'" :text="'<br />群号：607942298<br />验证：摸鱼'" :url="require('../../assets/qq.png')" :padding="10" :borderradius="2" :size="[200, 200]" :offset="[0, -10]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
                </span>
            </p> -->
        <!-- </div> -->

    </div>
</template>

<script>
import calendar from "../../assets/js/dateTranslate";
import tipBox from "../tipbox";

let timer = null;
let calScheduleTimer = null;

export default {
    name: "myside",
    components: {
        tipBox,
    },
    data() {
        return {
            topList: [],
            topLoading: false,
            isSettingOpen: false,
            isMobileShow: true,
            boardText: "",
            sloganIndex: -1,
            levelDesc:
                "摸鱼天数>10：渔人<br />摸鱼天数>30：渔夫<br />摸鱼天数>60：渔翁<br />摸鱼天数>100：海王<br />摸鱼天数>600：海神<br />摸鱼天数>1000：海尊",
            onlineCount: "-",
            moyuScheduleText: "",
            moyuScheduleWidth: 0,
        };
    },
    created() {
        let that = this;
        // 今日热门
        this.$store.state.setting.daytop && this.getTop();
        this.boardText = this.calcHoliday();
        this.getOnlineCount();
        timer = setInterval(() => {
            that.getOnlineCount();
        }, 20 * 1000);
    },
    mounted() {
        let that = this;
        that.calMoyuSchedule();
        timer = setInterval(() => {
            that.calMoyuSchedule();
        }, 1000);
    },
    beforeDestroy() {
        timer && clearInterval(timer);
        calScheduleTimer && clearInterval(calScheduleTimer);
    },
    computed: {
        daytopOpen() {
            return this.$store.state.setting.daytop;
        },
    },
    watch: {
        "$store.state.setting.quitelyMo"() {
            this.boardText = this.calcHoliday();
        },
        daytopOpen(val) {
            val && this.getTop();
        },
    },
    methods: {
        getOnlineCount() {
            let that = this;
            that.$({
                url: "/user/count",
                loading: false,
                success(res) {
                    that.onlineCount = res.data;
                },
            });
        },
        // 计算摸鱼
        calMoyuSchedule() {
            let workTime = this.$store.state.setting.workTime;
            let unit = ["¥", "$", "€", "₿"][workTime.unit] + " ";
            // 计算今日总工资
            let todayWages =
                Math.floor((workTime.wages / workTime.workDay) * 100) / 100;
            // 计算今天过了多少分钟
            let today =
                new Date().getTime() / 60 / 1000 -
                new Date(
                    `${new Date().getFullYear()}-${
                        new Date().getMonth() + 1
                    }-${new Date().getDate()} 00:00:00`
                ).getTime() /
                    60 /
                    1000;
            // 判断三种情况
            if (today < workTime.range[0]) {
                this.moyuScheduleWidth = 0;
                if (this.$store.state.setting.quitelyMo) {
                    this.moyuScheduleText = `今天预计要获得 ${todayWages} 学分，准备开始学习！`;
                } else {
                    this.moyuScheduleText = `今天预计可以赚 ${unit}${todayWages}，准备开摸！`;
                }
            } else if (today >= workTime.range[1]) {
                this.moyuScheduleWidth = 100;
                if (this.$store.state.setting.quitelyMo) {
                    this.moyuScheduleText = `今天你总共获得 ${todayWages} 学分，已达成！`;
                } else {
                    this.moyuScheduleText = `今天你总共赚了 ${unit}${todayWages}，赚够下班！`;
                }
            } else if (today >= workTime.range[0]) {
                // 计算百分比
                this.moyuScheduleWidth =
                    (today - workTime.range[0]) /
                    (workTime.range[1] - workTime.range[0]);
                // 计算当前工资
                let nowWages = (
                    Math.floor(todayWages * this.moyuScheduleWidth * 100) / 100
                ).toFixed(2);
                // 百分比保留两位小数
                this.moyuScheduleWidth =
                    Math.floor(this.moyuScheduleWidth * 10000) / 100;
                if (this.$store.state.setting.quitelyMo) {
                    this.moyuScheduleText = `今天你已经获得 ${nowWages} 学分，继续加油哦！`;
                } else {
                    this.moyuScheduleText = `今天你已经赚了 ${unit}${nowWages}，继续加油哦！`;
                }
            }
        },
        // 计算周末和节假日
        calcHoliday() {
            let now = new Date();
            let year = now.getFullYear();
            let date = `${now.getMonth() + 1}月${now.getDate()}日`;
            let weekDay = now.getDay();
            let hour = now.getHours();
            weekDay = weekDay === 0 ? 7 : weekDay;

            // 当前日期
            let day =
                "周" + ["一", "二", "三", "四", "五", "六", "日"][weekDay - 1];
            let dayPart = (() => {
                let text = "";
                switch (true) {
                    case hour < 6:
                        text = "凌晨";
                        break;
                    case hour < 9:
                        text = "早上";
                        break;
                    case hour < 12:
                        text = "上午";
                        break;
                    case hour < 14:
                        text = "中午";
                        break;
                    case hour < 17:
                        text = "下午";
                        break;
                    case hour < 19:
                        text = "傍晚";
                        break;
                    case hour < 23:
                        text = "晚上";
                        break;
                    default:
                        text = "晚上";
                        break;
                }
                return "的" + text;
            })();

            // 所有节假日包括周末
            let holidayArr = [];

            // 节假日[姓名，日期，是否农历节日]
            let holidayList = [
                ["元旦", [1, 1]],
                ["春节", [1, 1], true],
                ["清明节", [4, 5]],
                ["劳动节", [5, 1]],
                ["端午节", [5, 5], true],
                ["中秋节", [8, 15], true],
                ["国庆节", [10, 1]],
            ];
            for (let i = 0; i < holidayList.length; i++) {
                // 公历节日
                let gongli = [];
                // 农历节日
                if (holidayList[i][2]) {
                    // 一次性算今年和明年的节日
                    let nongliThis = calendar.lunar2solar(
                        year,
                        holidayList[i][1][0],
                        holidayList[i][1][1]
                    );
                    let nongliNext = calendar.lunar2solar(
                        year + 1,
                        holidayList[i][1][0],
                        holidayList[i][1][1]
                    );
                    gongli[0] = `${nongliThis.cYear}-${nongliThis.cMonth}-${nongliThis.cDay} 00:00:00`;
                    gongli[1] = `${nongliNext.cYear}-${nongliNext.cMonth}-${nongliNext.cDay} 00:00:00`;
                    // 公历节日
                } else {
                    gongli[0] = `${year}-${holidayList[i][1][0]}-${holidayList[i][1][1]} 00:00:00`;
                    gongli[1] = `${year + 1}-${holidayList[i][1][0]}-${
                        holidayList[i][1][1]
                    } 00:00:00`;
                }
                for (let k = 0; k < gongli.length; k++) {
                    let nowTimestrap = new Date().getTime();
                    let holidayTimestrap = new Date(gongli[k]).getTime();
                    let timeSpace = (holidayTimestrap - nowTimestrap) / 1000;
                    let timeSpaceDay =
                        Math.ceil(timeSpace / 86400) + (hour >= 18 ? -1 : 0);
                    // 筛选属于未来的节假日（90天内的节日）
                    if (timeSpaceDay > 0 && timeSpaceDay <= 90) {
                        holidayArr.push({
                            name: holidayList[i][0],
                            count: timeSpaceDay,
                        });
                    }
                }
            }

            holidayArr.sort((a, b) => {
                return a.count - b.count;
            });
            holidayArr = holidayArr.map((item) => {
                return `离${item.name}还有${item.count}天`;
            });

            // 周末
            let overCount = 7 - weekDay - (hour >= 18 ? 2 : 1);
            if (overCount < 1) {
                holidayArr.unshift(`周末愉快~`);
            } else {
                holidayArr.unshift(`离周末还有${overCount}天`);
            }

            // 口号[摸鱼，学习]
            let sloganArr = [
                [
                    "工作再累，一定不要忘记摸鱼哦！有事没事起身去茶水间，去厕所，去廊道走走。钱都是老板的，但生命是自己的。",
                    "生活再累，一定不要忘记学习哦！保持一颗开放进取之心，去读书，去四处旅行。用火热的青春，谱写似水的年华。",
                ],
                [
                    "停车坐爱枫林晚，不如摸鱼到下班。你摸我摸大家摸，老板豪车变摩托，摸鱼快乐。",
                    "书山有路勤为径，学到下班行不行？前进的道路总是痛苦的，但是只有承受痛苦才能化茧成蝶。",
                ],
                [
                    "认认真真地上班，根本不叫赚钱，这是劳动换取报酬。只有偷懒，在上班的时候浑水摸鱼，那才是从你老板那赚到了钱。",
                    "懒懒散散的日子，根本不叫生活，这是虚度光阴。只有学习，不断地奋发图强，那才是生活真正的意义。",
                ],
                [
                    "“时间是一直过的很快，还是只有在摸鱼的时候是？”",
                    "“时间是一直过的很快，还是只有在奋斗的时候是？”",
                ],
                [
                    "摸鱼人，安静了一早上，钱也偷偷的赚够了吧，可以愉快的摸鱼了吗？",
                    "年轻人，休息了一早上，精神也已内耗够了吧，可以认真的学习了吗？",
                ],
                [
                    "尼采说过，沉迷工作的现代人都是奴隶，所谓“工作的福祉”不过是奴隶们的自我崇高化，一种自我欺骗。对如今的多数年轻人而言也是如此，工作只是谋生手段，生活才是最终的答案。",
                    "孔子说过，三人行必有我师焉；择其善者而从之，其不善者而改之。无论何时何地我们都不能忘记学习。",
                ],
                [
                    "普希金说过，假如生活欺骗了你，不要悲伤，不要心急！忧郁的日子里需要镇静。相信吧，快乐的日子将会来临。而我们的快乐便是明天又能愉快地摸鱼！",
                    "普希金说过，假如生活欺骗了你，假如生活欺骗了你，不要悲伤，不要心急！忧郁的日子里需要镇静。相信吧，快乐的日子将会来临。而我们的快乐便是明天又能愉快地工作和学习！",
                ],
            ];
            // 一天一个口号
            if (this.sloganIndex === -1) this.sloganIndex = weekDay - 1;
            let slogan =
                sloganArr[this.sloganIndex][
                    Number(this.$store.state.setting.quitelyMo)
                ];

            return `${
                this.$store.state.setting.quitelyMo ? "学习" : "摸鱼"
            }提醒：今天是${date}，${day}${dayPart}
                <br />
                ${slogan}
                <br />
                <br />
                ${holidayArr.join("<br />")}
                `;
        },
        topRefresh() {
            let that = this;
            that.topLoading = true;
            that.getTop(() => {
                this.$toast("更新成功", "success");
                setTimeout(() => {
                    that.topLoading = false;
                }, 1000);
            });
        },
        getTop(done) {
            let that = this;
            that.$({
                url: "/hot/top",
                success(res) {
                    that.topList = res.data;
                    done && done();
                },
                error() {
                    that.$toast("更新失败", "error");
                },
                loading: false,
            });
        },
        // 点击热榜埋点
        record(id) {
            this.$({
                url: "/record",
                data: {
                    type: 1,
                    content: id,
                },
                type: "POST",
                loading: false,
            });
        },
        // 打开设置
        openSetting() {
            this.isSettingOpen = !this.isSettingOpen;
        },
    },
};
</script>

<style scoped lang="less">
.side {
    width: 360px;
    background-color: transparent;
    position: sticky;
    top: 20px;
    margin: 20px 20px 0 0;
}

.side-close {
    display: none;
}

.box {
    width: 100%;
    padding: 40px 20px 10px 20px;
    margin-bottom: 15px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.avatar {
    position: relative;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    background-color: #5983ce;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
    cursor: default;
    // background-image: url(../../assets/logo.png);
}

.name {
    margin-top: 10px;
    font-size: 20px;
}

.email {
    margin-top: 10px;
    font-size: 14px;
    font-weight: lighter;
    color: #aaa;
}

.text {
    font-size: 14px;
    margin-top: 10px;
}

.button-box-pc,
.button-box-mobile {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;

    .btn {
        margin: 0;
    }
}

.button-box-mobile {
    display: none;
}

.hot-title {
    width: calc(100% - 10px);
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    font-weight: bolder;
}

.hot-title-emoji {
    font-weight: normal;
}

.hot-top-box {
    height: 320px;
    padding: 20px 10px 20px 20px;
}

.hot-content {
    width: 100%;
    font-size: 0.82rem;
    padding-right: 10px;
    height: 385px;
    overflow-y: auto;

    .scroll-bar-hover(#efefef);

    > li {
        margin-top: 12px;

        > a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            > span:first-of-type {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            > span:last-of-type {
                font-size: 0.7rem;
                padding-left: 10px;
                white-space: nowrap;
            }
        }

        &:nth-of-type(1) > a > span > span {
            color: #cc3939;
        }

        &:nth-of-type(2) > a > span > span {
            color: #de6b30;
        }

        &:nth-of-type(3) > a > span > span {
            color: #cc984f;
        }
    }
}

.tips {
    padding: 20px;
    margin-bottom: 20px;
    color: #8a6d3b;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    border-radius: 20px;
    line-height: 1.4em;
    font-size: 14px;
    transition: border-color 0.3s, background-color 0.3s;

    .emoji {
        font-size: 16px;
    }

    .hover-show {
        cursor: pointer;
    }

    a,
    .hover-show {
        color: inherit;
        font-weight: bold;
    }

    a:visited {
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }

    .tips-button {
        user-select: none;
        display: flex;
        justify-content: space-between;

        a {
            display: block;
        }
    }
}

.icon-refresh {
    cursor: pointer;
}

.top-refresh {
    animation: topRefreshRotate 1s infinite linear;
}

@keyframes topRefreshRotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.moyu-schedule > p {
    padding-bottom: 10px;
}

.moyu-schedule-bar {
    position: relative;
    height: 12px;
    background-color: #eae0ab;
    // background-color: #373a40;
    border-radius: 4px;
    overflow: hidden;
    border-radius: 10px;

    > .moyu-schedule-bar-inside {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        width: 50%;
        height: 100%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        // background-color: #485860;
        background-color: #d2c169;
        transition: width 100ms linear 0s;
        animation: 3000ms linear 0s infinite normal none running
            animation-1mos065;
        background-size: 20px 20px;
        background-image: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.15) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.15) 50%,
            rgba(255, 255, 255, 0.15) 75%,
            transparent 75%,
            transparent
        );
    }

    > .moyu-schedule-bar-inside-done {
        animation: none;
    }
}

@keyframes animation-1mos065 {
    from {
        -webkit-background-position: 0 0;
        background-position: 0 0;
    }

    to {
        -webkit-background-position: 40px 0;
        background-position: 40px 0;
    }
}

.setting-bar {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0;

    a,
    a:hover {
        text-decoration: none;
    }
    a:hover,
    a:visited,
    a:link,
    a:active {
        color: inherit;
    }

    li {
        padding: 10px;
        transition: transform 0.4s, opacity 0.4s;
        border-radius: 10px;
        opacity: 0.8;

        &:hover {
            transform: scale(1.2);
            cursor: pointer;
            opacity: 1;
        }

        i {
            font-size: 26px;
            font-weight: lighter;
            vertical-align: middle;
        }
    }

    .setting-li {
        animation-name: bounce;
        animation-duration: 1s;
        animation-delay: 2s;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -20px, 0) scaleY(1.1);
        transform: translate3d(0, -15px, 0) scaleY(1.1);
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -10px, 0) scaleY(1.05);
        transform: translate3d(0, -15px, 0) scaleY(1.05);
    }

    80% {
        -webkit-transform: translateZ(0) scaleY(0.95);
        transform: translateZ(0) scaleY(0.95);
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    90% {
        -webkit-transform: translate3d(0, -3px, 0) scaleY(1.02);
        transform: translate3d(0, -3px, 0) scaleY(1.02);
    }
}

.theme-1 {
    .hot-content {
        .scroll-bar-hover(#18191e);
    }

    .tips {
        color: #b4b9bd;
        border-color: #797461;
        background-color: #615c47;

        > a {
            color: inherit;
        }

        > a:visited {
            color: inherit;
        }
    }

    .moyu-schedule-bar {
        background-color: #797256;

        .moyu-schedule-bar-inside {
            background-color: #7c7554;
        }
    }
}

.theme-1-btn {
    width: 100%;
    text-align: right;
    cursor: pointer;

    .theme-1-false {
        display: inline-block;
        transition: transform 0.3s;

        @sun: #ffce54;

        // 太阳主体
        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: @sun;
            transform: rotate(0deg);
            // 左右 左上右上 左下右下 上下
            box-shadow: 14px 0px 0 -8px @sun, -14px 0px 0 -8px @sun,
                10px -10px 0 -8px @sun, -10px -10px 0 -8px @sun,
                -10px 10px 0 -8px @sun, 10px 10px 0 -8px @sun,
                0px -14px 0 -8px @sun, 0px 14px 0 -8px @sun;
            transition: box-shadow 0.4s, transform 0.4s, transform 0.4s;
        }
    }

    .theme-1-true {
        // 太阳主体
        span {
            transform: rotate(180deg);
            box-shadow: 0 0 8px 3px #ffce54;
        }
    }
}

.theme-2 {
    .moyu-schedule-bar {
        height: 15px;
        background-color: #b9b9b9;
        border-radius: 0;
        border: 2px solid transparent;
        border-color: #838390 #d0d2d1 #d0d2d1 #838390;

        .moyu-schedule-bar-inside {
            background-color: #919191;
        }
    }
}

.theme-3 {
    .moyu-schedule-bar {
        background-color: #373a40;

        .moyu-schedule-bar-inside {
            background-color: #485860;
        }
    }
}

@media screen and (max-width: 768px) {
    .side {
        position: fixed;
        top: 0;
        left: 100%;
        width: 280px;
        height: 100%;
        margin: 0;
        background-color: #fff;
        box-shadow: 0 0 10px 0 #efefef;
        transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
    }

    .side-mobile-show {
        transform: translateX(-100%);
    }

    .box {
        padding: 20px 20px 0 20px;
    }

    .side-close {
        display: block;
        padding: 18px 20px 0 0;
        text-align: right;

        > i {
            font-size: 26px;
        }
    }

    .button-box-pc {
        display: none;
    }

    .button-box-mobile {
        display: flex;
    }

    .setting {
        max-height: 200px;

        > p {
            display: none;
        }
    }

    .hot-top-box {
        display: none;
    }

    .tips {
        margin: 20px;
    }

    .theme-1 {
        .side {
            background-color: #1f2025;
            box-shadow: 0 0 10px 0 #101010;
            overflow-y: auto;
        }
    }

    .moyu-schedule-tips {
        display: none;
    }
}
</style>