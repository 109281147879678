<template>
    <div class="box my-footer">
        <ul class="link line-right">
            <!-- <li @click="linkTo('https://github.com/peal-L')">
                <i class="iconfont icon-github-line"></i>
            </li> -->
            <li @click="linkTo('https://peal.cc')" title="PEAL的博客">
                <i class="iconfont icon-html"></i>
            </li>
            <li @mouseenter="$refs.tipbox.isShow = true" @mouseleave="$refs.tipbox.isShow = false">
                <i class="iconfont icon-weixin"></i>
                <tipBox :ref="'tipbox'" :url="require('../../assets/gzhss.png')" :padding="0" :borderradius="10" :size="[400, 146]" :offset="[0, -10]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
            </li>
        </ul>
        <div class="about">
            <p class="about-btn">
                <a href="/">首页</a>
                <a href="https://nav.momoyu.cc/" target="_blank">导航</a>
                <!-- <a href="https://peal.cc/player" target="_blank">听歌</a> -->
                <a href="https://support.qq.com/products/313868?" target="_blank">反馈</a>
                <router-link to="/rss">RSS订阅</router-link>
                <!-- <span @mouseenter="$refs.tipbox1.isShow = true" @mouseleave="$refs.tipbox1.isShow = false">反馈
                    <tipBox :ref="'tipbox1'" :text="'欢迎通过邮箱提意见建议以及BUG：674744961@qq.com'" :padding="10" :borderradius="2" :size="[150, 150]" :offset="[0, -10]" :shadow="'#f1f1f1 0px 0px 0px 2px'"></tipBox>
                </span> -->
            </p>
            <p class="copyright"><a href="http://beian.miit.gov.cn" target="_blank">© 2021 momoyu.cc 粤ICP备2020133024号</a></p>
            <p class="copyright"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202001391" target="_blank">粤公网安备 44011202001391号</a></p>
        </div>
    </div>
</template>

<script>
    import tipBox from '../tipbox'
    export default {
        name: 'myfooter',
        components: {
            tipBox
        },
        methods: {
            linkTo(url) {
                window.open(url);
            }
        }
    }
</script>

<style scoped lang="less">
    .box {
        width: 100%;
        padding: 20px;
        margin: 20px auto 0px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .link {
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-right: 10px;

        li {
            position: relative;
            padding: 13px 15px;
            margin: 0 10px;
            border-radius: 3px;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;

            i {
                font-size: 23px;
            }
        }

        li:hover {
            color: #efefef;
        }

        li:nth-of-type(1):hover {
            background-color: #067bbe;
        }

        li:nth-of-type(2):hover {
            background-color: #3cb035;
        }
    }

    .about {
        font-size: 12px;
    }

    .about-btn>a,
    .about-btn>span {
        position: relative;
        margin-right: 12px;
        cursor: pointer;
    }

    .about-btn>a:visited {
        color: inherit;
    }

    .copyright {
        padding-top: 10px;
        color: #ababab;

        >a:visited {
            color: inherit;
        }
    }

    // 移动端兼容
    @media screen and (max-width: 768px) {
        .box {
            display: none;
        }
    }
</style>