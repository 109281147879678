import axios from 'axios'
const baseUrl = process.env.VUE_APP_URL

const ajax = function (params) {
    if (!params.url) return false

    let requestParams = {
        url: params.url,
        method: params.type || 'get',
        baseURL: baseUrl,
        params: params.data || {},
        headers: params.headers || '',
        timeout: params.timeout || 0,
        responseType: params.dataType || 'json',
        withCredentials: true
    }

    // loading默认开启
    params.loading = params.loading == undefined ? true : params.loading
    params.loading && this.$store.commit('loading', true)

    axios(requestParams).then(res => {
        if (res.data.status === 100000) {
            params.success && params.success(res.data)
        }
        else {
            params.error && params.error(res.data)
        }
        params.complete && params.complete()
        params.loading && this.$store.commit('loading', false)
    }).catch(err => {
        params.complete && params.complete()
        params.error && params.error((err.response && err.response.data) || { message: '服务器错误，请稍候重试' })
        params.loading && this.$store.commit('loading', false)
    })
}

export default ajax