<template>
    <div class="my-content">
        <mycontent></mycontent>
        <myside></myside>
        <setting :isShow="$store.state.settingShow" @close="$store.commit('settingShow', false)"></setting>
    </div>
</template>

<script>
import myside from '@/components/layout/myside.vue'
import mycontent from '@/components/layout/mycontent.vue'
import setting from "@/components/setting.vue";

export default {
    name: 'home',
    components: {
        myside,
        mycontent,
        setting,
    },
}
</script>

<style lang="less" scoped>
.my-content {
    min-height: calc(100% - 180px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
</style>
