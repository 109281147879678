<template>
    <div class="content">

        <div class="content-pc" v-if="source[0]">
            <template v-for="(item, i) in source">
                <hotbox v-if="item.source_key" :key="'a' + i" :data="item" :index="item.id"></hotbox>
                <template v-else>
                    <p class="hot-sort-title" :key="'b' + i">{{item.name}}</p>
                    <div class="hot-sort-content" :key="i">
                        <hotbox v-for="(item2) in item.data" :key="'c' + item2.id" :data="item2"></hotbox>
                    </div>
                </template>
            </template>
        </div>

        <div class="content-mobile" v-if="source[0]">
            <!-- 一种带分类的资源 一种不带分类的资源 -->
            <hotboxM :data="sourceIndex[0] == -1 ? source[sourceIndex[1]] : source[sourceIndex[0]].data[sourceIndex[1]]"></hotboxM>
            <div class="source-mobile">
                <ul>
                    <template v-if="sourceIndex[0] == -1">
                        <li :class="{'source-selected': sourceIndex[1] == i}" v-for="(item, i) in source" :key="'d' + i" @click="changeSource([-1,i])">
                            <i :class="'iconfont icon-' + item.source_key" :style="'color:' + item.icon_color"></i>
                        </li>
                    </template>
                    <template v-else v-for="(item, i) in source">
                        <li :class="{'source-selected': i == sourceIndex[0] && k == sourceIndex[1]}" v-for="(item2, k) in item.data" :key="'e' + i + k" @click="changeSource([i,k])">
                            <i :class="'iconfont icon-' + item2.source_key" :style="'color:' + item2.icon_color"></i>
                        </li>
                    </template>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import hotbox from "@/components/hotbox.vue";
import hotboxM from "@/components/hotbox-m.vue";

export default {
    name: "mycontent",
    components: { hotbox, hotboxM },
    data() {
        return {
            source: [],
            sourceIndex: [-1, 0], // 第一个不为-1是带分类的
        };
    },
    computed: {
        // 关联到vuex
        settingSortChange() {
            return this.$store.state.setting.sort;
        },
    },
    watch: {
        // 监听vuex分类改变
        settingSortChange() {
            this.getList();
        },
    },
    methods: {
        // 获取热榜列表
        getList() {
            let that = this;
            that.sourceIndex = this.$store.state.setting.sort
                ? [0, 0]
                : [-1, 0];
            that.$({
                url:
                    "/hot/list?type=" +
                    (this.$store.state.setting.sort ? 1 : 0),
                success(res) {
                    that.source = res.data;
                },
            });
        },
        changeSource(index) {
            this.sourceIndex = index;
            this.$nextTick(
                () => (document.getElementById("app").scrollTop = 0)
            );
        },
        deleteItem(index) {
            // let duration = 1000
            // 获取删除前所有项
            let oldItems = document
                .getElementsByClassName("content")[0]
                .getElementsByClassName("hot-outer");
            let oldPos = [];
            for (let i = 0; i < oldItems.length; i++) {
                oldPos.push(oldItems[i].getBoundingClientRect());
            }

            // 从数组中删除指定元素
            this.source = this.source.filter(
                (value, indexx) => indexx !== index - 1
            );

            // 获取删除后所有项
            if (!window.test) {
                let items = document
                    .getElementsByClassName("content")[0]
                    .getElementsByClassName("hot-outer");
                for (let i = index; i < this.source.length; i++) {
                    let newPos = items[i].getBoundingClientRect();
                    // 计算同元素原来的位置的xy是不是等于现在的，如果是就置为0不进行该方向的位移，如果不是就计算现在的元素要位移过去多少（减）
                    let offset = [
                        oldPos[i + 1].left == newPos.left
                            ? 0
                            : oldPos[i + 1].left - newPos.left,
                        oldPos[i + 1].top == newPos.top
                            ? 0
                            : oldPos[i + 1].top - newPos.top,
                    ];
                    // y不相等说明要换行
                    if (oldPos[i + 1].top != newPos.top)
                        items[i].style.zIndex = 1;
                    items[i].style.transform =
                        "translate(" + offset[0] + "px," + offset[1] + "px)";
                    items[i].style.transition = "none";

                    setTimeout(() => {
                        items[i].style.transition = "all 5s";
                        items[i].style.transform = "translate(0,0)";
                        // setTimeout(() => {
                        // items[i].style.transition = 'none'
                        // items[i].style.zIndex = 'auto'
                        // }, duration)
                    }, 0);
                }
            }
            window.test = true;
            // }, duration)
        },
    },
    created() {
        this.getList();
    },
};
</script>

<style scoped lang="less">
.content {
    width: calc(100% - 400px);
}

.content-pc {
    width: 100%;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.content-mobile {
    width: 100%;
    display: none;
    margin-bottom: 80px;

    > .source-mobile {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: auto;
        background-color: #f2f4f9;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);

        > ul {
            white-space: nowrap;

            > li {
                display: inline-block;
                padding: 18px 20px;

                > i {
                    font-size: 26px;
                }
            }

            > .source-selected {
                background-color: #d8dbe2;
            }
        }
    }
}

@supports (bottom: constant(safe-area-inset-bottom)) or
    (bottom: env(safe-area-inset-bottom)) {
    .source-mobile {
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.hot-sort-title {
    width: 100%;
    padding: 15px 20px;
    margin: 10px;
    background-color: #fff;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.hot-sort-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.theme-1 {
    .hot-sort-title {
        background-color: #1f2025;
    }
    .content-mobile {
        > .source-mobile {
            background-color: #18191e;
            > ul > .source-selected {
                background-color: #252731;
            }
        }
    }
}

// 移动端兼容
@media screen and (max-width: 768px) {
    .content {
        width: 100%;
        margin: 0;
    }

    .content-pc {
        display: none;
    }

    .content-mobile {
        display: block;
    }
}
</style>
