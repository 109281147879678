<template>
    <div class="hot-outer" v-if="data.name">
        <div class="hot-title">
            <div class="hot-title-inner">
                <a class="hot-logo" :href="data.link" target="_blank">
                    <span class="hot-title-name">{{data.name}}<span class="hot-title-time">{{data.create_time ? calcUpdateTime : ''}}</span></span>
                </a>
            </div>
        </div>
        <ul class="hot-content">
            <li v-for="(item, i) in data.data" :key="i">
                <a :href="item.link" target="_blank" :title="item.title" @click="record(item.id)">
                    <span>{{i + 1}}. </span>
                    <span :class="{'wrap': $store.state.setting.hotItemComplete}">{{item.title}}</span>
                    <span>{{item.extra}}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'hotboxM',
    props: {
        data: {
            type: Object,
            default() {
                return {
                    name: '',
                    source_key: '',
                    icon_color: '',
                    data: []
                }
            }
        },
        index: Number
    },
    data() {
        return {
            currentTime: new Date().getTime()
        }
    },
    methods: {
        // 点击热榜埋点
        record(id) {
            this.$({
                url: '/record',
                data: {
                    type: 1,
                    content: id
                },
                type: 'POST',
                loading: false
            })
        },
        deleteItem(index) {
            this.$emit('deleteItem', index)
        }
    },
    computed: {
        calcUpdateTime() {
            // 时间差距的秒数
            let space = (this.currentTime - new Date(this.data.create_time).getTime()) / 1000
            let text = ''
            // 分钟判断
            let minute = space / 60
            if (minute < 1) {
                text = '刚刚更新'
            }
            else {
                // 小时判断
                let hour = minute / 60
                if (hour < 1) {
                    text = Math.floor(minute) + '分钟前'
                }
                else {
                    // 天数判断
                    let day = hour / 24
                    if (day < 1) {
                        text = Math.floor(hour) + '小时前'
                    }
                    else {
                        text = Math.floor(day) + '天前'
                    }
                }
            }
            return '（' + text + '）'
        }
    },
    mounted() {
        let that = this
        // 定时刷新时间
        setInterval(() => {
            that.currentTime = new Date().getTime()
        }, 60 * 1000)
    }
}
</script>

<style lang="less" scoped>
.hot-outer {
    width: 100%;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.hot-title {
    width: 100%;
    padding: 0 20px;

    > .hot-title-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        > .hot-logo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            > i {
                font-size: 1.6rem;
                margin-right: 8px;
            }
            > .hot-title-name {
                margin-right: auto;
                font-weight: bolder;

                > .hot-title-time {
                    margin-right: auto;
                    font-size: 0.7rem;
                }
            }
        }
    }
}

.hot-content {
    padding: 0 8px 0 20px;
    margin-right: 7px;
    font-size: 0.82rem;

    .scroll-bar-hover(#efefef);

    > li {
        margin-top: 12px;

        > a {
            display: flex;
            justify-content: space-between;
            width: 100%;
            line-height: 1.3em;

            > span:nth-of-type(1) {
                padding-right: 3px;
            }

            > span:nth-of-type(2) {
                margin-right: auto;
                word-break: break-all;
            }

            > span:nth-of-type(3) {
                font-size: 0.7rem;
                padding-left: 10px;
                white-space: nowrap;
            }
        }

        &:nth-of-type(1) > a > span:nth-of-type(1) {
            color: #cc3939;
        }
        &:nth-of-type(2) > a > span:nth-of-type(1) {
            color: #de6b30;
        }
        &:nth-of-type(3) > a > span:nth-of-type(1) {
            color: #cc984f;
        }
    }
}

.theme-1 {
    .hot-outer {
        background-color: #1f2025;
    }
}

@media screen and (max-width: 768px) {
    .hot-outer {
        width: 100%;
    }
}
</style>