<template>
    <div class="inputbtn">
        <span @click="down">{{downText}}</span>
        <input type="text" readonly :value="value" :style="'width:' + inputWidth + 'px'">
        <span @click="up">{{upText}}</span>
    </div>
</template>

<script>
export default {
    name: "loading",
    props: {
        value: {
            type: String,
            default: ''
        },
        inputWidth: {
            type: Number,
            default: 30
        },
        downText: {
            type: String,
            default: '-'
        },
        upText: {
            type: String,
            default: '+'
        },
    },
    methods: {
        // 改变事件
        down() {
            this.$emit('down')
        },
        up() {
            this.$emit('up')
        }
    }
};
</script>

<style scoped lang="less">
.inputbtn {
    display: inline-block;
    box-shadow: 0 0 0px 1px #ececec;
    border-radius: 5px;
    overflow: hidden;
    user-select: none;
    vertical-align: middle;
    transition: box-shadow 0.3s;

    > span {
        display: inline-block;
        font-size: 14px;
        width: 25px;
        height: 100%;
        text-align: center;
        padding: 5px 8px;
        background-color: #fff;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    > span:hover {
        background-color: #efefef;
    }

    > input {
        width: 30px;
        height: 24px;
        box-sizing: border-box;
        font-size: 12px;
        padding: 5px 8px;
        border-radius: 0;
        background-color: #fff;
        vertical-align: bottom;
        text-align: center;
    }
}
.theme-1 {
    .inputbtn {
        box-shadow: 0 0 0px 1px #737373;
        color: #444;
        > span,
        > input {
            background-color: #eee;
        }
    }
}
</style>
