<template>
    <div class="box line-bottom my-header">
        <img class="logo" src="@/assets/logo-1.png" alt="logo">
        <input :style="$route.path == '/' ? '' : 'visibility: hidden'" type="text" class="input search" placeholder="搜索 ..." v-model="searchVal" @keyup.enter="search">
        <div class="btn-box">
            <button v-if="$store.state.user.id" class="btn btn-red" @click="logout()">退出登录</button>
            <div v-else>
                <button class="btn btn-green-full" @click="loginShow(1)">登录</button>
                <button class="btn btn-blue-full" @click="loginShow(2)">注册</button>
            </div>
        </div>
        <div class="sidebar-btn">
            <!-- 主页显示更多，其他页面则显示关闭 -->
            <i v-if="$route.path=='/'" class="iconfont icon-elipsis" @click="$store.commit('mobileSideShow', true)"></i>
            <i v-else class="iconfont icon-close" @click="$router.push({path: '/'})"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'myheader',
        data: function() {
            return {
                searchVal: ''
            }
        },
        methods: {
            // 调出登录注册页面
            loginShow(type) {
                this.$router.push({
                    path: '/login',
                    query: {
                        type: type
                    }
                })
            },
            // 搜索
            search(e) {
                let that = this
                if (!this.$store.state.user.id) {
                    this.$router.push({
                        path: '/login'
                    })
                    this.$toast('需要登录')
                } else if (that.searchVal.replaceAll(' ', '') !== '') {
                    e.srcElement.blur()
                    that.$({
                        url: '/hot/search?text=' + that.searchVal,
                        success(res) {
                            that.$store.state.popupHot = {
                                isShow: true,
                                title: `【${that.searchVal}】`,
                                content: res.data
                            }
                            that.$nextTick(() => {
                                setTimeout(() => {
                                    document.querySelector('.popup').focus()
                                }, 100)
                            })
                        },
                        error() {
                            that.$toast('请求失败', 'error')
                        }
                    })
                } else {
                    that.$toast('搜索内容不能为空', 'error')
                }
            },
            // 退出登录
            logout() {
                let that = this
                that.$({
                    url: '/user/logout',
                    success() {
                        that.$base.delCookie('token')
                        that.$toast('退出登录成功', 'success')
                        that.$store.commit('user', {})
                        setTimeout(() => {
                            location.reload()
                        }, 1000)
                    },
                    error() {
                        that.$toast('退出登录失败', 'error')
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .box {
        width: 100%;
        padding: 5px 14px 5px 20px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        width: 30px;
        margin: 10px 0;
    }

    .search {
        width: 280px;
        margin: 10px auto 10px 10px;
    }

    .sidebar-btn {
        display: none;
        padding: 10px 5px;

        >i {
            font-size: 22px;
        }
    }

    // 移动端兼容
    @media screen and (max-width: 768px) {
        .search {
            width: 42%;
        }

        .btn-box {
            display: none;
        }

        .sidebar-btn {
            display: block;
        }
    }
</style>