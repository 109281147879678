<template>
    <div class="hot-outer" v-if="data.name">
        <div class="hot-inner">
            <div class="hot-title">
                <div class="hot-title-inner">
                    <a class="hot-logo" :href="'/history?id=' + data.id" target="_blank" title="点击查看该平台历史">
                        <i v-if="$store.state.setting.logo && data.source_key" :class="'iconfont icon-' + data.source_key" :style="'color:' + data.icon_color"></i>
                        <span class="hot-title-name">{{data.name}}<span class="hot-title-time">{{data.create_time ? calcUpdateTime : ''}}</span></span>
                    </a>
                    <!-- <i class="iconfont icon-close" @click="deleteItem(index)"></i> -->
                    <!-- <i class="iconfont icon-refresh"></i> -->
                    <i :class="{'iconfont icon-refresh': true, 'icon-refreshing': refreshing}" @click="!refreshing && getLatest()"></i>
                </div>
            </div>
            <ul class="hot-content">
                <li v-for="(item, i) in list" :key="i">
                    <a :href="item.link" target="_blank" :title="item.title" @click="record(item.id)">
                        <span>{{i + 1}}. </span>
                        <span :class="{'wrap': $store.state.setting.hotItemComplete}">{{item.title}}</span>
                        <span>{{item.extra}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "hotbox",
    props: {
        data: {
            type: Object,
            default() {
                return {
                    name: "",
                    source_key: "",
                    icon_color: "",
                    data: [],
                };
            },
        },
        index: Number,
    },
    data() {
        return {
            currentTime: new Date().getTime(),
            refreshing: false,
        };
    },
    methods: {
        // 点击热榜埋点
        record(id) {
            this.$({
                url: "/record",
                data: {
                    type: 1,
                    content: id,
                },
                type: "POST",
                loading: false,
            });
        },
        getLatest() {
            let that = this;
            that.refreshing = true;
            this.$({
                url: "/hot/item?id=" + that.data.id,
                loading: false,
                complete() {
                    setTimeout(() => (that.refreshing = false), 1000);
                },
                success(res) {
                    that.data.data = res.data.list;
                    that.data.create_time = res.data.time;
                    that.$toast("[" + that.data.name + "] 刷新成功", "success");
                },
                error() {
                    that.$toast("刷新失败", "error");
                },
            });
        },
        // deleteItem(index) {
        //     this.$emit('deleteItem', index)
        // },
    },
    computed: {
        list() {
            return this.data.data.slice();
        },
        calcUpdateTime() {
            // 时间差距的秒数
            let space =
                (this.currentTime - new Date(this.data.create_time).getTime()) /
                1000;
            let text = "";
            // 分钟判断
            let minute = space / 60;
            if (minute < 1) {
                text = "刚刚更新";
            } else {
                // 小时判断
                let hour = minute / 60;
                if (hour < 1) {
                    text = Math.floor(minute) + "分钟前";
                } else {
                    // 天数判断
                    let day = hour / 24;
                    if (day < 1) {
                        text = Math.floor(hour) + "小时前";
                    } else {
                        text = Math.floor(day) + "天前";
                    }
                }
            }
            return "（" + text + "）";
        },
    },
    mounted() {
        let that = this;
        // 定时刷新时间
        setInterval(() => {
            that.currentTime = new Date().getTime();
        }, 60 * 1000);
    },
};
</script>

<style lang="less" scoped>
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(0, 50px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
.hot-outer {
    opacity: 0;
    width: 50%;
    animation: fadeIn 0.5s forwards;

    &:nth-of-type(1) {
        animation-delay: 0s;
    }

    &:nth-of-type(2) {
        animation-delay: 0.05s;
    }

    &:nth-of-type(3) {
        animation-delay: 0.1s;
    }

    &:nth-of-type(4) {
        animation-delay: 0.15s;
    }

    &:nth-of-type(5) {
        animation-delay: 0.2s;
    }

    &:nth-of-type(6) {
        animation-delay: 0.25s;
    }

    &:nth-of-type(7) {
        animation-delay: 0.3s;
    }

    &:nth-of-type(8) {
        animation-delay: 0.35s;
    }

    &:nth-of-type(9) {
        animation-delay: 0.4s;
    }

    &:nth-of-type(10) {
        animation-delay: 0.45s;
    }

    &:nth-of-type(11) {
        animation-delay: 0.5s;
    }

    &:nth-of-type(12) {
        animation-delay: 0.55s;
    }

    &:nth-of-type(13) {
        animation-delay: 0.6s;
    }

    &:nth-of-type(14) {
        animation-delay: 0.65s;
    }

    &:nth-of-type(15) {
        animation-delay: 0.7s;
    }

    &:nth-of-type(16) {
        animation-delay: 0.75s;
    }

    &:nth-of-type(17) {
        animation-delay: 0.8s;
    }

    &:nth-of-type(18) {
        animation-delay: 0.85s;
    }

    &:nth-of-type(19) {
        animation-delay: 0.9s;
    }

    &:nth-of-type(20) {
        animation-delay: 0.95s;
    }

    .hot-inner {
        margin: 10px;
        padding: 20px 0;
        background-color: #fff;
        border-radius: 20px;
        transition: background-color 0.3s;
    }

    .icon-refresh {
        display: none;
        cursor: pointer;
        font-weight: bolder;
    }

    .icon-refreshing {
        animation: refreshRotate 1s linear;
    }

    @keyframes refreshRotate {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &:hover {
        .icon-refresh {
            display: inline;
        }
    }
}

.hot-title {
    width: 100%;
    padding: 0 20px;

    > .hot-title-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        > .hot-logo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:visited {
                color: inherit;
            }

            &:hover {
                color: inherit;
            }

            &:active {
                color: inherit;
            }

            &:focus {
                color: inherit;
            }

            > i {
                font-size: 1.6rem;
                margin-right: 8px;
            }

            > .hot-title-name {
                margin-right: auto;
                font-weight: bolder;

                > .hot-title-time {
                    margin-right: auto;
                    font-size: 0.7rem;
                }
            }
        }
    }
}

.hot-content {
    height: 320px;
    padding: 0 8px 0 20px;
    margin-right: 7px;
    font-size: 0.82rem;
    overflow: auto;

    .scroll-bar-hover(#efefef);

    > li {
        margin-top: 12px;

        > a {
            display: flex;
            justify-content: space-between;
            width: 100%;
            line-height: 1.3em;

            > span:nth-of-type(1) {
                padding-right: 3px;
            }

            > span:nth-of-type(2) {
                margin-right: auto;
                word-break: break-all;
            }

            > span:nth-of-type(3) {
                font-size: 0.7rem;
                padding-left: 10px;
                white-space: nowrap;
            }
        }

        &:nth-of-type(1) > a > span:nth-of-type(1) {
            color: #cc3939;
        }

        &:nth-of-type(2) > a > span:nth-of-type(1) {
            color: #de6b30;
        }

        &:nth-of-type(3) > a > span:nth-of-type(1) {
            color: #cc984f;
        }
    }
}

.theme-1 {
    .hot-inner {
        background-color: #1f2025;
    }

    .hot-content {
        .scroll-bar-hover(#18191e);
    }
}

// 窗口自适应

@media screen and (min-width: 1360px) {
    .hot-outer {
        width: 33.33%;
    }
}

@media screen and (min-width: 1666px) {
    .hot-outer {
        width: 25%;
    }
}

@media screen and (min-width: 2000px) {
    .hot-outer {
        width: 20%;
    }
}

@media screen and (min-width: 2400px) {
    .hot-outer {
        width: 16.66%;
    }
}
</style>