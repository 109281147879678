import Vue from 'vue'
import Vuex from 'vuex'
import myVue from '../main'

Vue.use(Vuex)

// 本地存储中取出全局设置参数
let settingRaw = localStorage.getItem('setting')
let setting = { "theme": 1, "fontSize": 16, "sort": false, "logo": true, "daytop": false, "hotItemComplete": false, "quitelyMo": false, "workTime": { "isOpen": false, "range": [480, 1080], "unit": 0, "wages": 1800, "workDay": 21.75 } }
// 设置存在
if (settingRaw) {
    // 设置应用储存里面的设置
    try {
        let settingParse = JSON.parse(settingRaw)
        if (settingParse && typeof settingParse.theme == 'number' && typeof settingParse.sort == 'boolean' && typeof settingParse.logo == 'boolean' && typeof settingParse.daytop == 'boolean' && typeof settingParse.hotItemComplete == 'boolean' && typeof settingParse.quitelyMo == 'boolean' && typeof settingParse.fontSize == 'number'
            && (typeof settingParse.workTime == 'object' &&
                typeof settingParse.workTime.isOpen == 'boolean' &&
                typeof settingParse.workTime.range == 'object' &&
                typeof settingParse.workTime.unit == 'number' &&
                typeof settingParse.workTime.wages == 'number' &&
                typeof settingParse.workTime.workDay == 'number')
        ) {
            setting = settingParse
        }
        // 设置格式无效，用默认配置取代
        else {
            localStorage.setItem('setting', JSON.stringify(setting))
        }
    }
    catch {
        localStorage.setItem('setting', JSON.stringify(setting))
    }
}
else {
    localStorage.setItem('setting', JSON.stringify(setting))
}

// 默认关闭今日热门（影响性能暂时关闭）
// setting.daytop = true
document.documentElement.style.fontSize = setting.fontSize + 'px'
document.title = setting.quitelyMo ? '在线学习_免费学习站_授人以鱼不如授人以渔' : '摸摸鱼热榜'

export default new Vuex.Store({
    state: {
        user: {}, // 用户信息
        setting: setting, // 全局设置
        loading: false,
        loginShow: 0,
        isSideShow: false,
        popupHot: {
            isShow: false,
            title: '',
            content: []
        },
        settingShow: false
    },
    mutations: {
        user: (state, val) => {
            state.user = val;
        },
        setting: (state, val) => {
            state.setting = val;
            document.documentElement.style.fontSize = state.setting.fontSize + 'px'
        },
        settingTheme: (state, val) => {
            let themeIndex = state.setting.theme + val
            if (isNaN(Number(themeIndex))) themeIndex = 1
            if (themeIndex < 0) themeIndex = 3
            if (themeIndex > 3) themeIndex = 0
            state.setting.theme = themeIndex
            saveSetting(state)
        },
        changeTheme: (state, val) => {
            state.setting.theme = val
            saveSetting(state)
        },
        settingFontSizeUp: (state) => {
            if (state.setting.fontSize < 20) {
                state.setting.fontSize++
                document.documentElement.style.fontSize = state.setting.fontSize + 'px'
                saveSetting(state)
            }
        },
        settingFontSizeDown: (state) => {
            if (state.setting.fontSize > 12) {
                state.setting.fontSize--
                document.documentElement.style.fontSize = state.setting.fontSize + 'px'
                saveSetting(state)
            }
        },
        settingLogo: (state) => {
            state.setting.logo = !state.setting.logo
            saveSetting(state)
        },
        settingDaytop: (state) => {
            state.setting.daytop = !state.setting.daytop
            saveSetting(state)
        },
        settingSort: (state) => {
            state.setting.sort = !state.setting.sort
            saveSetting(state)
        },
        settingItemComplete: (state) => {
            state.setting.hotItemComplete = !state.setting.hotItemComplete
            saveSetting(state)
        },
        settingQuitelyMo: (state) => {
            state.setting.quitelyMo = !state.setting.quitelyMo
            document.title = state.setting.quitelyMo ? '在线学习_免费学习站_授人以鱼不如授人以渔' : '摸摸鱼热榜'
            saveSetting(state)
        },
        settingWorkTime: (state, val) => {
            if (!state.user.id) {
                myVue.$router.push({
                    path: '/login'
                })
                myVue.$toast('需要登录')
            }
            else {
                state.setting.workTime[val[0]] = val[1]
                saveSetting(state)
            }
        },
        loading: (state, val) => {
            state.loading = val
        },
        loginShow: (state, val) => {
            state.loginShow = val
        },
        mobileSideShow: (state, val) => {
            state.isSideShow = val == undefined ? !state.isSideShow : val
        },
        saveSetting: (state) => {
            saveSetting(state)
        },
        settingShow: (state, val) => {
            state.settingShow = val
        },
    },
    actions: {
    },
    modules: {
    }
})

// 保存设置到云端
function saveSetting(state) {
    let settingRaw = JSON.stringify(state.setting)
    if (state.user.id) {
        window.uploadTimer && clearTimeout(window.uploadTimer)
        window.uploadTimer = setTimeout(() => {
            Vue.prototype.$({
                url: '/user/info',
                data: {
                    setting: settingRaw
                },
                type: 'POST',
                loading: false
            })
        }, 1000)
    }
    localStorage.setItem('setting', settingRaw)
}