<template>
    <div class="app" id="app" :class="'theme-' + $store.state.setting.theme">
        <myheader></myheader>
        <transition name="fade">
            <router-view />
        </transition>
        <myfooter></myfooter>
        <loading :control="$store.state.loading"></loading>
        <popupHot :control="$store.state.popupHot"></popupHot>
    </div>
</template>

<script>
import myheader from '@/components/layout/myheader.vue'
import myfooter from '@/components/layout/myfooter.vue'
import loading from '@/components/loading'
import popupHot from '@/components/popup-hot'

export default {
    name: 'app',
    components: {
        myheader,
        myfooter,
        loading,
        popupHot
    },
    created() {
        let that = this
        that.$({
            url: 'user/info',
            success(res) {
                that.$store.commit('user', res.data)
            },
            error(res) {
                // 登录失效则清空token和页面用户信息
                if (res.status && res.status == 100404) {
                    that.$store.commit('user', {})
                    that.$base.setCookie('token', '', -1)
                }
            },
            loading: false
        })
        // ios13系统监听深色模式
        let ver = navigator.appVersion ? (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/) : ''
        ver = (ver && ver[1]) ? parseInt(ver[1], 10) : null
        if (ver >= 13) {
            // 监听深色模式切换
            window.matchMedia('(prefers-color-scheme: dark)').addListener((mediaQueryList) => {
                if (mediaQueryList.matches) {
                    that.$store.commit('settingDarkMode', true)
                }
            })
            window.matchMedia('(prefers-color-scheme: light)').addListener((mediaQueryList) => {
                if (mediaQueryList.matches) {
                    that.$store.commit('settingDarkMode', false)
                }
            })

            if (window.matchMedia('(prefers-color-scheme: dark)').matches) { that.$store.commit('settingDarkMode', true) }
        }
    }
}
</script>

<style lang="less">
@import url(assets/css/reset.css);
@import url(assets/css/base.less);
@import url(//common.peal.cc/css/icon.css);

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>
