// 数组删除某项
Array.prototype.remove = function (dx) {
    if (isNaN(dx) || dx > this.length) {
        return false
    }
    let arr = this
    for (var i = 0, n = 0; i < arr.length; i++) {
        if (arr[i] != arr[dx]) {
            arr[n++] = arr[i]
        }
    }
    arr.length -= 1
    return arr
};

const base = {


    regMail: new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/),
    regPwd: new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)(?![#@!~$.%^&*]+$)[0-9A-Za-z#@!~$.%^&*]{6,20}$/),


    // 设置cookie
    setCookie(name, value, day) {
        let date = new Date()
        date.setTime(date.getTime() + (day || 7) * 24 * 60 * 60 * 1000)
        document.cookie = name + "=" + escape(value) + ";expires=" + date.toGMTString() + ";path=/"
    },
    // 获取cookie
    getCookie(name) {
        let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)")
        let arr = document.cookie.match(reg)
        if (arr && arr[2]) {
            return unescape(arr[2])
        }
        else {
            return null
        }
    },
    // 删除cookie
    delCookie(name) {
        let value = this.getCookie('token')
        let domain = location.hostname;
        if (value) {
            document.cookie = name + "=" + value + '; expires=' + new Date(0).toUTCString() + '; path=/'
            document.cookie = name + "=" + value + '; expires=' + new Date(0).toUTCString() + '; path=/; domain=' + domain
        }
    },
}

export default base