<template>
    <div :class="{'switch': true, 'switch-on': control}">
        <div class="switch-block" :on-text="onText" :off-text="offText" @click="change"></div>
        <p class="switch-space">
            <span>{{onText}}</span>
            <span>{{offText}}</span>
        </p>
    </div>
</template>

<script>
export default {
    name: "loading",
    props: {
        control: {
            type: Boolean,
            default: false,
        },
        onText: {
            type: String,
            default: "ON",
        },
        offText: {
            type: String,
            default: "OFF",
        },
    },
    methods: {
        // 改变事件
        change() {
            this.$emit("switch");
        },
    },
};
</script>

<style scoped lang="less">
.switch {
    position: relative;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0 0 0px 1px #ececec;
    overflow: hidden;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    font-size: 12px;
    transition: box-shadow 0.3s;

    > .switch-space {
        > span {
            display: inline-block;
            padding: 5px 8px;
        }
    }

    > .switch-block {
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #fff;
        transform: translateX(0);
        transition: transform 0.3s, background-color 0.3s;

        &::before {
            content: attr(on-text);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            top: 0;
            color: #fff;
            background-color: #337ab7;
        }

        &::after {
            content: attr(off-text);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 100%;
            top: 0;
            color: #fff;
            background-color: #bbb;
        }
    }
}

.switch-on {
    > .switch-block {
        transform: translateX(100%);
    }
}

.theme-1 {
    .switch {
        box-shadow: 0 0 0px 1px #737373;

        > .switch-block {
            background-color: #eee;
        }
    }
}
</style>
