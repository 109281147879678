<template>
    <div :class="'popup ' + (control.isShow ? 'popup-show' : '')" @keydown.esc="$store.state.popupHot.isShow = false" tabindex="0">
        <div class="popup-center">
            <div class="hot-outer">
                <div class="hot-inner">
                    <div class="hot-title">
                        <div class="hot-title-inner">
                            <a class="hot-logo" :href="control.link" target="_blank">
                                <i v-if="$store.state.setting.logo && control.source_key" :class="'iconfont icon-' + data.source_key" :style="'color:' + data.icon_color"></i>
                                <span class="hot-title-name">{{control.title}}</span>
                            </a>
                            <i class="iconfont icon-close" @click="$store.state.popupHot.isShow = false"></i>
                        </div>
                    </div>
                    <ul class="hot-content">
                        <li v-for="(item, i) in control.content" :key="i">
                            <a :href="item.link" target="_blank" :title="item.title" @click="record(item.id)">
                                <span>{{i + 1}}. </span>
                                <span :class="{'wrap': $store.state.setting.hotItemComplete}">{{item.title}}</span>
                                <span>{{item.name}} - {{item.create_time}}</span>
                            </a>
                        </li>
                        <li v-if="control.content.length === 0">
                            <a href="javascript:void(0);" style="justify-content: center;">
                                <span>无数据</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            control: Object
        },
        methods: {
            // 点击热榜埋点
            record(id) {
                this.$({
                    url: '/record',
                    data: {
                        type: 1,
                        content: id
                    },
                    type: 'POST',
                    loading: false
                })
            },
            deleteItem(index) {
                this.$emit('deleteItem', index)
            }
        }
    };
</script>

<style scoped lang="less">
    .popup {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00000088;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1002;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
    }

    .popup-show {
        opacity: 1;
        visibility: visible;
    }

    .popup-center {
        width: 100%;
    }

    .hot-outer {
        min-width: 350px;
        max-width: 550px;
        margin: 0 auto;

        .hot-inner {
            padding: 20px 0;
            background-color: #fff;
            border-radius: 4px;
            transition: background-color 0.3s;
        }

        .icon-close {
            cursor: pointer;
        }
    }

    .hot-title {
        width: 100%;
        padding: 0 20px;

        >.hot-title-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;

            >.hot-logo {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                >i {
                    font-size: 1.6rem;
                    margin-right: 8px;
                }

                >.hot-title-name {
                    margin-right: auto;
                    font-weight: bolder;
                }
            }
        }
    }

    .hot-content {
        height: 450px;
        padding: 0 8px 0 20px;
        margin-right: 7px;
        font-size: 0.82rem;
        overflow: auto;

        .scroll-bar-hover(#efefef);

        >li {
            margin-top: 12px;

            >a {
                display: flex;
                justify-content: space-between;
                width: 100%;
                line-height: 1.3em;

                >span:nth-of-type(1) {
                    padding-right: 3px;
                }

                >span:nth-of-type(2) {
                    margin-right: auto;
                    word-break: break-all;
                }

                >span:nth-of-type(3) {
                    font-size: 0.7rem;
                    padding-left: 10px;
                    white-space: nowrap;
                }
            }

            &:nth-of-type(1)>a>span:nth-of-type(1) {
                color: #cc3939;
            }

            &:nth-of-type(2)>a>span:nth-of-type(1) {
                color: #de6b30;
            }

            &:nth-of-type(3)>a>span:nth-of-type(1) {
                color: #cc984f;
            }
        }
    }

    .theme-1 {
        .hot-inner {
            background-color: #1f2025;
        }

        .hot-content {
            .scroll-bar-hover(#18191e);
        }
    }

    // 移动端兼容
    @media screen and (max-width: 768px) {
        .hot-outer {
            min-width: 100%;
        }
    }
</style>