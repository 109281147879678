<template>
    <div @mouseenter.stop="stopPropagation()" @mouseleave.stop="stopPropagation()" :class="{
        'tip': true,
        'tip-show': isShow,
        ['tip-arrow-' + arrowpos]: true,
        }" :style="{
        'background-color': backgound,
        'padding': padding + 'px',
        'box-shadow': shadow ||'none',
        'border-radius': borderradius + 'px',
        'transform': 'translate(calc(-50% ' + (offset[0] < 0 ? '- ' : '+ ') + Math.abs(offset[0]) + 'px), calc(-100% - ' + (isShow ? 15 : 0) + 'px ' + (offset[1] < 0 ? '- ' : '+ ') + Math.abs(offset[1]) + 'px))'
        }">
        <img v-if="url" class="tip-img" :style="{width: size[0] + 'px', height: size[1] + 'px'}" :src="url" alt="">
        <p v-if="text" class="tip-text" v-html="text"></p>
    </div>
</template>

<script>
export default {
    name: "tipbox",
    props: {
        url: {
            type: String,
            default: ''
        },
        size: {
            type: Array,
            default() {
                return [120, 120]
            }
        },
        text: {
            type: String,
            default: ''
        },
        padding: {
            type: Number,
            default: 10
        },
        shadow: {
            type: String,
            default: ''
        },
        arrowpos: {
            type: String,
            default: 'center'
        },
        backgound: {
            type: String,
            default: '#fff'
        },
        borderradius: {
            type: Number,
            default: 10
        },
        offset: {
            type: Array,
            default() {
                return [0, 0]
            }
        }
    },
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        show() {
            this.isShow = true
        },
        hide() {
            this.isShow = false
        },
        stopPropagation() {
        }
    },
};
</script>

<style scoped lang="less">
.tip {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s, transform 0.4s;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        transform: translate(-50%, 99%);
        border: 10px solid transparent;
        border-top-color: #fff;
    }

    > .tip-img {
        display: block;
        // width: 160px;
        // height: 160px;
    }

    > .tip-text {
        white-space: nowrap;
        color: #666;
        font-size: 12px;
        cursor: text;
    }
}

.tip-show {
    opacity: 1;
    visibility: visible;
}

.tip-arrow-left::after {
    left: 32px;
}

.tip-arrow-center::after {
    left: 50%;
}

.tip-arrow-right::after {
    right: 10px;
}
</style>
