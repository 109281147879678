import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ajax from './assets/js/ajax'
import base from './assets/js/base'
import toast from '@/components/toast/toast.js'

Vue.use(toast)

Vue.config.productionTip = false
// 封装好的ajax方法挂载到vue原型上
Vue.prototype.$ = ajax
Vue.prototype.$base = base

const myVue = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default myVue