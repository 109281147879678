<template>
    <div :class="{'setting-cover': true, 'setting-show': isShow}" @click="$emit('close')">
        <div class="setting-box" @click.stop>
            <p class="title">
                <i class="iconfont icon-setting"></i>
                <span>设置</span>
                <i class="iconfont icon-close" @click="$emit('close')"></i>
            </p>
            <div class="setting-content">
                <div class="setting-item">
                    <label>主题切换</label>
                    <inputBtn :value="themeArr[$store.state.setting.theme]" :inputWidth="50" :downText="'<'" :upText="'>'" @down="$store.commit('settingTheme', -1)" @up="$store.commit('settingTheme', 1)"></inputBtn>
                </div>
                <div class="setting-item">
                    <label>字体大小</label>
                    <inputBtn :value="$store.state.setting.fontSize.toString()" @down="$store.commit('settingFontSizeDown')" @up="$store.commit('settingFontSizeUp')"></inputBtn>
                </div>
                <div class="setting-item">
                    <label>偷摸模式</label>
                    <myswitch :control="$store.state.setting.quitelyMo" :onText="'开启'" :offText="'关闭'" @switch="$store.commit('settingQuitelyMo')"></myswitch>
                </div>
                <div class="setting-item">
                    <label>超出隐藏</label>
                    <myswitch :control="$store.state.setting.hotItemComplete" :onText="'开启'" :offText="'关闭'" @switch="$store.commit('settingItemComplete')"></myswitch>
                </div>
                <div class="setting-item">
                    <label>LOGO显示</label>
                    <myswitch :control="$store.state.setting.logo" :onText="'开启'" :offText="'关闭'" @switch="$store.commit('settingLogo')"></myswitch>
                </div>
                <div class="setting-item">
                    <label>首页分类</label>
                    <myswitch :control="$store.state.setting.sort" :onText="'开启'" :offText="'关闭'" @switch="$store.commit('settingSort')"></myswitch>
                </div>
                <div class="setting-item">
                    <label>今日热门</label>
                    <myswitch :control="$store.state.setting.daytop" :onText="'显示'" :offText="'隐藏'" @switch="showDaytop"></myswitch>
                </div>
                <p class="cutline"></p>
                <div class="setting-item">
                    <label>🔥摸鱼进度</label>
                    <myswitch :control="$store.state.setting.workTime.isOpen" :onText="'开启'" :offText="'关闭'" @switch="$store.commit('settingWorkTime', ['isOpen', !$store.state.setting.workTime.isOpen])"></myswitch>
                </div>
                <div :class="{'setting-worktime': true, 'setting-worktime-disabled': !$store.state.setting.workTime.isOpen}">
                    <div class="setting-item setting-item-block">
                        <label>工作时间</label>
                        <timePicker value-format="HH:mm" :clearable="false" is-range v-model="workTimePicker" @change="time2Second" format="HH:mm" size="small" start-placeholder="上班时间" end-placeholder="下班时间"></timePicker>
                        <Slider size="small" v-model="$store.state.setting.workTime.range" @change="sliderChange" range :max="1439" :marks="markWorkTime" :format-tooltip="second2Time" :show-input="true"></Slider>
                    </div>
                    <div class="setting-item setting-item-block">
                        <label>薪酬</label>
                        <Input size="small" placeholder="月入一千八，每天笑哈哈" v-model.number="$store.state.setting.workTime.wages" @change="settingChange">
                        <Select size=" small" v-model="$store.state.setting.workTime.unit" @change="settingChange" slot="append" placeholder="请选择">
                            <Option v-for="(item,i) in wagesObj" :key="i" :label="item.label" :value="item.value"></Option>
                        </Select>
                        </Input>
                    </div>
                    <div class="setting-item setting-item-block">
                        <label>月工作天数</label>
                        <InputNumber size="small" v-model="$store.state.setting.workTime.workDay" @change="settingChange" :min="1" :max="31" label="请输入"></InputNumber>
                    </div>
                </div>
            </div>
            <div>
                <button class="btn btn-green-full btn-block" @click="$emit('close')">开摸！</button>
            </div>
        </div>
    </div>
</template>

<script>
import myswitch from "./switch";
import inputBtn from "./input-btn";
import Vue from "vue";
import {
    Slider,
    timePicker,
    Input,
    InputNumber,
    Select,
    Option,
} from "element-ui";
Vue.use(Slider);
Vue.use(timePicker);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);

export default {
    name: "setting",
    components: {
        myswitch,
        inputBtn,
        Slider,
        timePicker,
        Input,
        InputNumber,
        Select,
        Option,
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            themeArr: ["正常", "暗夜", "极客", "护眼"],
            workTimePicker: ["", ""],
            markWorkTime: {
                480: "08:00",
                1080: "18:00",
            },
            wagesObj: [
                {
                    label: "CNY ¥",
                    value: 0,
                },
                {
                    label: "USD $",
                    value: 1,
                },
                {
                    label: "EUR €",
                    value: 2,
                },
                {
                    label: "BTC ₿",
                    value: 3,
                },
            ],
        };
    },
    created() {
        this.sliderChange(this.$store.state.setting.workTime.range);
    },
    methods: {
        show() {
            this.isShow = true;
        },
        hide() {
            this.isShow = false;
        },
        // 今日热门开启关闭
        showDaytop() {
            // this.$toast('该功能暂时关闭', 'warn')
            this.$store.commit("settingDaytop");
        },
        sliderChange(val) {
            this.workTimePicker = [
                this.second2Time(val[0]),
                this.second2Time(val[1]),
            ];
            this.settingChange();
        },
        // 时间转秒
        time2Second(val) {
            this.$store.commit("settingWorkTime", [
                "range",
                [this.parseTime(val[0]), this.parseTime(val[1])],
            ]);
        },
        parseTime(val) {
            let arr = val.split(":");
            return Number(arr[0]) * 60 + Number(arr[1]);
        },
        // 秒转时间
        second2Time(val) {
            return (
                this.formatTime(Math.floor(val / 60)) +
                ":" +
                this.formatTime(val % 60)
            );
        },
        formatTime(time) {
            return time.toString().padStart(2, "0");
        },
        settingChange() {
            this.$store.commit("saveSetting");
        },
    },
};
</script>

<style scoped lang="less">
.setting-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 1024px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;
}

.setting-show {
    opacity: 1;
    visibility: visible;

    > .setting-box {
        transform: translateY(0);
    }
}

.setting-box {
    width: 600px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: #00000022 0px 1px 10px 0px;
    overflow: hidden;
    transform: translateY(20px);
    transition: transform 0.4s;

    > .title {
        font-size: 18px;
        font-weight: bold;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > i {
            font-size: 20px;
        }

        > span {
            margin: 0 auto 0 6px;
        }

        > .icon-close {
            padding: 5px;
            cursor: pointer;
        }
    }

    > .setting-content {
        padding: 20px 60px 40px 20px;

        > p {
            text-align: right;

            > .icon-setting {
                font-size: 22px;
                cursor: pointer;
                display: inline-block;
                transform: rotate(0deg);
                transition: transform 0.5s;
            }
        }

        .setting-item {
            display: inline-block;
            width: 50%;
            padding: 12px 0;

            > label {
                display: inline-block;
                width: 100px;
                text-align: right;
                padding-right: 15px;
            }
        }

        .setting-item-block {
            width: 100%;
        }

        > .cutline {
            width: 100%;
            height: 1.5px;
            background-color: #ebebeb;
            margin: 15px 0;
        }
    }
}

.setting-worktime {
    width: 100%;
    transition: opacity 0.4s;

    .el-input-group .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
        width: 341px;
    }

    .el-slider {
        margin-left: 100px;
    }

    /deep/ .el-slider__runway.show-input {
        width: 341px;
        margin-right: 0;
    }

    .el-input-group {
        width: 341px;
    }

    /deep/ .el-input-group__append {
        width: 100px;
    }
}

.setting-worktime-disabled {
    opacity: 0.3;
    pointer-events: none;
}

.btn-block {
    margin: 0;
    border-radius: 0;
}

.theme-1 {
    .setting-box {
        background-color: #1f2025;
        box-shadow: #00000066 0px 1px 10px 0px;

        .cutline {
            background-color: #373943;
        }
    }

    .setting-cover {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.theme-2 {
    .setting-box {
        background-color: #a2a0a0;
        box-shadow: #00000088 3px 3px 0px 0px;
        border-radius: 0;

        .cutline {
            background-color: #727272;
        }
    }

    .setting-cover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .setting-worktime {
        /deep/ .el-range-input {
            background-color: #e3e3e3;
        }

        /deep/ .el-input__inner {
            background-color: #e3e3e3;
        }

        /deep/.el-input__inner.is-active,
        /deep/.el-input__inner {
            border-radius: 0;
            border: 2px solid transparent;
            border-color: #838390 #d0d2d1 #d0d2d1 #838390;
        }

        /deep/ .el-slider__bar {
            background-color: #337ab7;
        }

        /deep/ .el-slider__stop {
            background-color: #cacaca;
            border-radius: 0;
        }

        /deep/ .el-slider__marks-text {
            color: #060202;
        }

        /deep/ .el-tooltip__popper.is-dark {
            border-radius: 0;
        }

        /deep/ .el-input-group__append {
            border-radius: 0;
            border: none;
        }

        /deep/ .el-select.el-select--.small {
            border-radius: 0;
            background-color: #e3e3e3;
        }

        /deep/ .el-input-number__decrease,
        /deep/ .el-input-number__increase,
        /deep/ .el-input-number__decrease:hover,
        /deep/ .el-input-number__increase:hover {
            top: 2px;
            border-radius: 0;
            border: none;
            color: inherit;
            background-color: #d0d2d1;
        }

        /deep/ .el-input-number__decrease {
            left: 2px;
        }

        /deep/ .el-input-number__increase {
            right: 2px;
        }

        /deep/
            .el-input-number__decrease:hover:not(.is-disabled)
            ~ .el-input
            .el-input__inner:not(.is-disabled),
        /deep/
            .el-input-number__increase:hover:not(.is-disabled)
            ~ .el-input
            .el-input__inner:not(.is-disabled) {
            border-color: #838390 #d0d2d1 #d0d2d1 #838390;
        }
    }
}

.theme-3 {
    .setting-box {
        background-color: #333e43;
        box-shadow: #00000066 0px 1px 10px 0px;

        .cutline {
            background-color: #47545b;
        }
    }

    .setting-cover {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

@media screen and (max-width: 768px) {
    .setting-cover {
        top: 0;
        left: 0;
        z-index: 999;
        min-width: 380px;
    }
    .setting-box {
        width: 100%;
        height: 100%;
        overflow: auto;
        border-radius: 0;
        padding-bottom: 60px;

        .setting-content {
            padding: 20px;

            .setting-item {
                min-width: 202px;
            }
        }
    }

    /deep/ .el-slider__runway.show-input,
    .el-input-group .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner,
    .el-input-group {
        width: 240px !important;
    }

    .btn-block {
        position: relative;
        width: 230px;
        margin-top: 20px;
        margin: 0 auto;
        border-radius: 6px;
        left: 50%;
        transform: translate(-50%, 20px);
    }

    .cutline,
    .setting-worktime {
        display: none;
    }
    .setting-box > .setting-content {
        .setting-item:nth-of-type(3),
        .setting-item:nth-of-type(5),
        .setting-item:nth-of-type(6),
        .setting-item:nth-of-type(7),
        .setting-item:nth-of-type(8) {
            display: none;
        }
    }
}
</style>